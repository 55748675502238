/* Google Fonts */
@font-face {
  font-family: Montserrat;
  src: url(../Fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
  font-display: swap;
}

@font-face {
  font-family: PlayfairDisplay;
  src: url(../Fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf);
  font-display: swap;
}

@font-face {
  font-family: BigshotOne;
  src: url(../Fonts/Bigshot_One/BigshotOne-Regular.ttf);
  font-display: swap;
}

:root {
  /**
  @font family declaration
  */
  /* --tp-ff-body: 'DM Sans', sans-serif;
  --tp-ff-heading: 'Raleway', sans-serif;
  --tp-ff-poppins: 'Poppins', sans-serif;
  --tp-ff-p: 'DM Sans', sans-serif;
  --tp-ff-fontawesome: "Font Awesome 6 Pro"; */
  /**
  @font size declaration
  */
  --tp-fz-body: 16px;
  --tp-fz-p: 16px;
  /**
  @color declaration
  */
  --tp-common-white: #ffffff;
  --tp-them-dark-background: #101827;
  --tp-common-black: #000;
  --tp-heading-primary: #020626;
  --tp-heading-secondary: #000000;
  --tp-grey-1: #F1F1F1;
  --tp-grey-2: #F5F6F8;
  --tp-text-body: #5A627D;
  --tp-text-1: #261F1F;
  --tp-text-2: #0C0808;
  --tp-theme-primary: #020626;
  --tp-theme-secondary: #05DAC3;
  --tp-bg-primary: #F3FBFE;
  --tp-border-primary: #000;
  --tp-border-secondary: #000;

}



body {
  font-family: Montserrat !important;
  color: black;
}

.white-content {
  background: var(--tp-them-dark-background);
  color: var(--tp-common-white);
}

.text-center {
  text-align: center;
}





/*----------------------------- Light Dark Screen Intigration In CSS----------------------*/

.white-content .main-card {
  color: var(--tp-common-black);
}

.white-content .image-text {
  color: black;
}

/* 
.white-content .header-without-scroll {
  background-color: var(--tp-them-dark-background);
}

.white-content .header-without-scroll .digiverse-text {
  color: var(--tp-common-white);
}

.white-content .header-without-scroll .Nav a {
  color: var(--tp-common-white);
} */

.white-content .header-with-scroll {
  background-color: var(--tp-them-dark-background);
}

.white-content .header-with-scroll .Nav a {
  color: var(--tp-common-white);
}

.white-content .header-with-scroll .digiverse-text {
  color: var(--tp-common-white);
}

.white-content .tp-contact-title {
  color: var(--tp-common-white);
}

.white-content .tp-contact-title-wrapper p {
  color: var(--tp-common-white);
}

.white-content .tp-contact-item-title a {
  color: var(--tp-common-white);
}

.white-content .tp-contact-item-title span {
  color: var(--tp-common-white);
}

.white-content .tp-contact-location-title {
  color: var(--tp-common-white);
}

.white-content .tp-contact-location p {
  color: var(--tp-common-white);
}

/* .white-content .tp-contact-item-title a {
  color: var(--tp-common-black);
} */

.white-content .tp-contact-item-title .call-text {
  color: var(--tp-common-black);
}

.white-content .tp-portfolio-details-title {
  color: var(--tp-common-white);
}

.white-content .tp-portfolio-details-wrapper p {
  color: var(--tp-common-white);

}

.white-content .tp-portfolio-details-list-title {
  color: var(--tp-common-white);
}

.white-content .tp-about-3-wrapper .text {
  color: var(--tp-common-white);
}

.white-content .tp-about-3-progressbar-title p {
  color: var(--tp-common-white);
}

.carousel.carousel-slider .control-arrow {
  background: #000;
}


.white-content .testimonial-name {
  color: var(--tp-border-secondary)
}

.white-content .testimonial-quote {
  color: var(--tp-border-secondary)
}

.white-content .testimonial-designation {
  color: var(--tp-border-secondary)
}

.white-content .estimate-tp-number-text {
  color: var(--tp-common-black) !important;
}

.white-content .estimate-tp-number-text:hover {
  color: var(--tp-theme-secondary) !important;
  transition: color 0.3s 0s ease-out;
}

.white-content .header-with-scroll .Nav a:hover {
  color: var(--tp-common-white);
}


/*----------------------------- HeaderComponent Section ----------------------*/

.header-without-scroll {
  position: absolute;
  top: 0;
  height: 100px;
  width: 100%;
  display: grid;
  grid-template-areas: "logo nav";
  transition: 0.5s;
  z-index: 1000;

}

.header-without-scroll .Nav a {
  color: black;
}

.header-without-scroll .digiverse-text {
  color: black;
  font-family: BigshotOne;
  font-size: 30px;
  margin-left: 15px;
  letter-spacing: 2px;
}

.header-with-scroll .digiverse-text {
  color: black;
  font-family: BigshotOne;
  font-size: 30px;
  margin-left: 15px;
  letter-spacing: 2px;

}

.header-with-scroll {
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  display: grid;
  grid-template-areas: "logo nav";
  background: var(--tp-common-white);
  z-index: 1000;
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  transition: 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.header-with-scroll .Nav a {
  color: var(--tp-border-secondary);
}

.header-with-scroll .Nav a:hover {
  transform: scale(1);
  color: #000;
  font-weight: bold;
  font-size: 22px;
}

.Logo {
  grid-area: logo;
  height: 70px;
}

.Nav {
  display: grid;
  grid-area: nav;
  grid-template-columns: repeat(6, auto);
  align-items: center;
  justify-items: center;
}

.Nav a {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  transition: 0.5s;
  text-decoration: none;
}

.Nav a:hover {
  transform: scale(1);
  color: #000;
  font-size: 22px;
}

.toggle-navigation {
  display: none;
  grid-area: hamburger;
  margin: 0 20px 0 0;
  padding: 0;
  justify-self: end;
  font-size: 40px;
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
}

.toggle-navigation:active {
  transform: scale(1.2);
}

.smallscreen-non {
  opacity: 0;
  display: none;
}

.digiverse-logo-conatiner {
  display: flex;
  margin-left: 70px;
  align-items: center;
  gap: 10px;
}

.digiverse-logo {
  width: 80px;
  height: 80px;
}

.digiverse-logo img {
  width: 100%;
  height: 100%;
}

.digiverse-logo-conatiner {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
}

.digiverse-logo-conatiner:hover {
  color: #fff;
}


/*----------------------------- Main Card HomePage Section ----------------------*/

.banner-image {
  position: absolute;
  top: 0;
  z-index: -1;
  min-height: 100vh;
  width: 100%;
  /* clip-path: polygon(50% 0%, 100% 0, 100% 35%, 100% 89%, 75% 96%, 46% 97%, 18% 95%, 0 90%, 0% 35%, 0 0); */
}

.banner-image img {
  width: 100%;
  height: 100%;
}

.image-text {
  margin-top: 100px;
}

.main-content {
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.after-banner-image-section {
  /* min-height: calc(121vh - 62px); */
  /* min-height: 960px; */
  display: flex;
  justify-content: center;
  padding-top: 67%;
  position: relative;
  /* align-items: center; */
}

/* .banner-floting-image img {
  background-color: var(--tp-common-white);

} */


.banner-floting-image .shape-1 {
  position: absolute;
  top: 50%;
  right: 20%;
  height: auto;
  width: 12%;
  max-width: 125px;
  border-radius: 50%;
  object-fit: cover;
  animation: movingleft 9s linear infinite;
}

.banner-floting-image .shape-2 {
  position: absolute;
  top: 50%;
  right: 10%;
  height: auto;
  width: 12%;
  max-width: 125px;
  border-radius: 50%;
  object-fit: cover;
  animation: movingleft 7s linear infinite;
}

.banner-floting-image .shape-3 {
  position: absolute;
  top: 50%;
  right: 60%;
  height: auto;
  width: 12%;
  max-width: 125px;
  border-radius: 50%;
  object-fit: cover;
  animation: movingright 8s linear infinite;
}

.banner-floting-image .shape-4 {
  position: absolute;
  top: 15%;
  right: 15%;
  height: auto;
  width: 12%;
  max-width: 125px;
  object-fit: cover;
  border-radius: 50%;
  padding-top: 32px;
  animation: movingleft 5s linear infinite;
}

.banner-floting-image .shape-5 {
  position: absolute;
  top: 30%;
  left: 20%;
  height: auto;
  width: 12%;
  max-width: 125px;
  object-fit: cover;
  border-radius: 50%;
  padding-top: 15px;
  animation: movingright 10s linear infinite;
}

.banner-floting-image .shape-6 {
  position: absolute;
  top: 11%;
  left: 50%;
  height: auto;
  width: 12%;
  max-width: 125px;
  object-fit: cover;
  border-radius: 50%;
  padding-top: 45px;
  animation: movingleft 11s linear infinite;
}

.banner-floting-image .shape-7 {
  position: absolute;
  top: 40%;
  left: 63%;
  height: auto;
  width: 12%;
  max-width: 125px;
  object-fit: cover;
  border-radius: 50%;
  animation: movingright 12s linear infinite;
}

.banner-floting-image .shape-8 {
  position: absolute;
  top: 24%;
  left: 35%;
  height: auto;
  width: 12%;
  max-width: 125px;
  object-fit: inherit;
  border-radius: 50%;
  padding-top: 15px;
  animation: movingleft 9s linear infinite;
}

.banner-floting-image .shape-9 {
  position: absolute;
  top: 40%;
  left: 10%;
  height: auto;
  width: 12%;
  max-width: 125px;
  object-fit: cover;
  border-radius: 50%;
  animation: movingright 12s linear infinite;
}

.banner-floting-image .shape-10 {
  position: absolute;
  top: 65%;
  left: 15%;
  height: auto;
  width: 12%;
  max-width: 125px;
  object-fit: cover;
  border-radius: 50%;
  animation: movingright 12s linear infinite;
}

.partner-main-card {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

@media (max-width: 768px) {
  .header-without-scroll {
    height: 65px;
  }

  .header-with-scroll {
    height: 65px;
  }

  .digiverse-logo {
    width: 45px;
    height: 45px;
  }

  .header-without-scroll .digiverse-text {
    font-size: 25px;
    margin-left: 0px;
  }

  .header-with-scroll .digiverse-text {
    font-size: 25px;
    margin-left: 0px;
  }

  .faBars {
    font-size: 30px;
  }

  .digiverse-logo-conatiner {
    margin-left: 20px;
  }

  .partner-main-card {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .digiverse-your-digital-text {
    font-size: 20px;
  }

  .partner-card-title {
    font-size: 20px;
  }

  .partner-card-description {
    font-size: 14px;
  }
}

/* theme btn */
.tell-help-btn {
  position: relative;
  z-index: 1;
  overflow: hidden;
  /* font-family: var(--tp-ff-heading); */
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 21px 48px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  display: inline-block;
  color: #fff;
  background: #020626;
  text-decoration: none;
  cursor: pointer;
}

.tell-help-btn::after {
  position: absolute;
  content: "";
  background: #05DAC3;
  height: 34px;
  width: 36px;
  border-radius: 15px;
  right: -11px;
  bottom: -11px;
}

.tell-help-btn::before {
  content: "";
  position: absolute;
  height: 400px;
  width: 430px;
  top: 50%;
  left: 50%;
  background: #05DAC3;
  border-radius: 50%;
  transform: translateX(-22%) translateY(-42%) scale(0);
  transition: all 0.5s ease-out 0s;
  z-index: -1;
}

.tell-help-btn:hover {
  color: #020626;
}

.tell-help-btn:hover::before {
  transform: translateX(-50%) translateY(-50%) scale(1);
  cursor: pointer;
}

.tell-help-btn i {
  margin-left: 16px;
  transform: translateY(2px);
}

/*----------------------------- NavBar HeaderPage Section ----------------------*/
.navbar-bg {
  /* background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%); */
  background: transparent;
  color: black !important;
  font-weight: bold;
}

.navbar-brand {
  color: black !important;
}

.nav-link {
  color: black !important;
}





/*----------------------------- Card EditPage Section ----------------------*/
.card-image {
  overflow: hidden;
  width: 250px;
  height: 250px;
  border-radius: 31.945px;
  margin-bottom: 20px;
}

.card-image img {
  width: 100%;
}

.partner-card {
  background: antiquewhite;
  box-shadow: -1px 1px 16px 2px #01010121;
  border-radius: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 450px;
}





/*---------------------------------- Responsive Media Querys Section -------------------*/

/*///-- HeaderComponent Section --///*/
@media (max-width: 700px) {
  .Header {
    grid-template-areas: "logo hamburger" "nav nav";
  }

  .Nav {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: none;
    grid-row-gap: 20px;
    padding: 30px 0 30px;
    /* background: var(--tp-them-dark-background); */
    background: var(--tp-common-white);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    width: 100%;
    top: 63px;
    z-index: 100;
    display: none;
  }

  .white-content .Nav {
    background-color: var(--tp-them-dark-background);
  }


  .Nav.open {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .white-content .Nav.open a {
    color: var(--tp-common-white);
  }

  .toggle-navigation {
    display: inline;
  }

  .white-content .toggle-navigation {
    color: var(--tp-common-white);
  }


  .image-text {
    margin: 0;
  }

  .header-without-scroll {
    background-color: var(--tp-common-white);
  }

  .white-content .header-without-scroll {
    background-color: var(--tp-them-dark-background);
  }

  .header-without-scroll .digiverse-text {
    color: var(--tp-common-white);
  }

  .header-with-scroll {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .header-without-scroll {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}



/*---------------------------------- Animation Classs -------------------*/


/*///-- Header Section --///*/
@keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

/*///-------------------- Banneer Image Section ------------------///*/
@keyframes moving {
  0% {
    transform: translatey(0px);
  }

  25% {
    transform: translatex(20px);
  }

  50% {
    transform: translatey(-20px);
  }

  75% {
    transform: translatex(-20px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes movingleft {
  0% {
    transform: translatey(0px);
  }

  25% {
    transform: translatey(50px);
  }

  50% {
    transform: translatex(-50px);
  }

  75% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatex(0px);
  }
}

@keyframes movingright {
  0% {
    transform: translatey(0px);
  }

  25% {
    transform: translatey(30px);
  }

  50% {
    transform: translatex(-30px);
  }

  75% {
    transform: translatey(-40px);
  }

  100% {
    transform: translatex(0px);
  }
}


/*///-------------------- Banneer Image Section ------------------///*/
@keyframes icon-bounce {

  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}






/*----------------------------- Home Page BrandLogo Section ----------------------*/
.tp-brand-area {
  background: #F3FBFE;
  overflow: hidden;
  z-index: 1;
}

@media (max-width: 575px) {
  .tp-brand-area {
    padding-top: 60px;
  }
}

.tp-brand-shape .bg-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.5;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-brand-shape .bg-shape {
    max-width: 100%;
  }
}

.tp-brand-shape .shape-1 {
  position: absolute;
  bottom: 60px;
  left: 38%;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-brand-shape .shape-1 {
    bottom: 70px;
    left: 35%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-brand-shape .shape-1 {
    bottom: 77px;
    left: 32%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-brand-shape .shape-1 {
    bottom: 72px;
    left: 25%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-brand-shape .shape-1 {
    left: 25%;
  }
}

@media (max-width: 575px) {
  .tp-brand-shape .shape-1 {
    display: none;
  }
}

.tp-brand-shape .shape-2 {
  position: absolute;
  bottom: 145px;
  left: 29%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-brand-shape .shape-2 {
    left: 23%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-brand-shape .shape-2 {
    bottom: 160px;
    left: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-brand-shape .shape-2 {
    bottom: 155px;
    left: 14%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-brand-shape .shape-2 {
    bottom: 190px;
    left: 5%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-brand-shape .shape-2 {
    left: 5%;
  }
}

@media (max-width: 575px) {
  .tp-brand-shape .shape-2 {
    display: none;
  }
}

.tp-brand-shape .shape-3 {
  position: absolute;
  bottom: 41%;
  left: 23%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-brand-shape .shape-3 {
    bottom: 43%;
    left: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-brand-shape .shape-3 {
    bottom: 44%;
    left: 17%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-brand-shape .shape-3 {
    left: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-brand-shape .shape-3 {
    bottom: 50%;
    left: 5%;
  }

  .white-content .header-with-scroll .Nav a {
    color: var(--tp-common-black);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-brand-shape .shape-3 {
    bottom: 42%;
    left: 0%;
  }
}

@media (max-width: 575px) {
  .tp-brand-shape .shape-3 {
    display: none;
  }
}

.tp-brand-shape .shape-4 {
  position: absolute;
  top: 20%;
  left: 34%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-brand-shape .shape-4 {
    top: 17%;
    left: 33%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-brand-shape .shape-4 {
    top: 16%;
    left: 33%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-brand-shape .shape-4 {
    top: 18%;
    left: 28%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-brand-shape .shape-4 {
    top: 14%;
    left: 28%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-brand-shape .shape-4 {
    top: 13%;
    left: 18%;
  }
}

@media (max-width: 575px) {
  .tp-brand-shape .shape-4 {
    display: none;
  }
}

.tp-brand-shape .shape-5 {
  position: absolute;
  top: 7%;
  left: 47%;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-brand-shape .shape-5 {
    right: 16%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-brand-shape .shape-5 {
    top: 5%;
    left: 42%;
  }
}

@media (max-width: 575px) {
  .tp-brand-shape .shape-5 {
    display: none;
  }
}

.tp-brand-shape .shape-6 {
  position: absolute;
  right: 31%;
  top: 20%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-brand-shape .shape-6 {
    right: 26%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-brand-shape .shape-6 {
    right: 23%;
    top: 18%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-brand-shape .shape-6 {
    right: 16%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-brand-shape .shape-6 {
    right: 10%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-brand-shape .shape-6 {
    right: 0%;
    top: 16%;
  }
}

@media (max-width: 575px) {
  .tp-brand-shape .shape-6 {
    display: none;
  }
}

.tp-brand-shape .shape-7 {
  position: absolute;
  right: 22%;
  top: 44%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-brand-shape .shape-7 {
    right: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-brand-shape .shape-7 {
    right: 15%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-brand-shape .shape-7 {
    right: 8%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-brand-shape .shape-7 {
    right: 0%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-brand-shape .shape-7 {
    right: 0%;
    top: 44%;
  }
}

@media (max-width: 575px) {
  .tp-brand-shape .shape-7 {
    display: none;
  }
}

.tp-brand-shape .shape-8 {
  position: absolute;
  right: 27%;
  bottom: 13%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-brand-shape .shape-8 {
    right: 23%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-brand-shape .shape-8 {
    right: 17%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-brand-shape .shape-8 {
    right: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-brand-shape .shape-8 {
    right: 2%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-brand-shape .shape-8 {
    right: 0%;
    bottom: 12%;
  }
}

@media (max-width: 575px) {
  .tp-brand-shape .shape-8 {
    display: none;
  }
}

.tp-brand-shape .shape-9 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-brand-shape .shape-9 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-brand-shape .shape-9 {
    display: none;
  }
}

.tp-brand-thumb {
  position: relative;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-brand-thumb img {
    max-width: 100%;
  }
}

.dg-clinet-brand-area {
  /* margin-top: 40px; */
  margin-bottom: 40px;
  position: relative;
  /* overflow: hidden; */
  background-image: url('../images/brand-logo/shape-bg.png');
  padding-top: 80px;
}

.dg-clinet-brand-left {
  margin-left: 30px;
}

.dg-clinet-brand-right {
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .dg-clinet-brand-bg {
    bottom: -35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dg-clinet-brand-bg {
    bottom: -220px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .dg-clinet-brand-item {
    text-align: center;
  }
}



.dg-clinet-brand-item img {
  box-shadow: 0px 7px 29px rgba(13, 39, 69, 0.05);
  border-radius: 50%;
  transition: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
  height: 135px;
  width: 135px;
}

.dg-clinet-brand-item .shape-1 {
  margin-top: 10px;
}

.dg-clinet-brand-item .shape-1:hover {
  -webkit-transform: translate(-20px, -30px);
  -ms-transform: translate(-20px, -30px);
  transform: translate(-20px, -30px);
  transition-delay: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
}

.dg-clinet-brand-item .shape-2 {
  margin-left: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dg-clinet-brand-item .shape-2 {
    margin-left: 180px;
  }
}

@media (max-width: 575px) {
  .dg-clinet-brand-item .shape-2 {
    margin-left: 50px;
    margin-top: 35px;
  }
}

.dg-clinet-brand-item .shape-2:hover {
  -webkit-transform: translate(-20px, -30px);
  -ms-transform: translate(-20px, -30px);
  transform: translate(-20px, -30px);
  transition-delay: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
}

.dg-clinet-brand-item .shape-3 {
  margin-top: 85px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dg-clinet-brand-item .shape-3 {
    margin-top: 20px;
    margin-left: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .dg-clinet-brand-item .shape-3 {
    margin-top: 35px;
  }
}

.dg-clinet-brand-item .shape-3:hover {
  -webkit-transform: translate(-20px, -30px);
  -ms-transform: translate(-20px, -30px);
  transform: translate(-20px, -30px);
  transition-delay: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
}

.dg-clinet-brand-item .shape-4 {
  margin-top: -80px;
  margin-left: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .dg-clinet-brand-item .shape-4 {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dg-clinet-brand-item .shape-4 {
    margin-top: 0;
    margin-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dg-clinet-brand-item .shape-4 {
    margin-top: 35px;
    margin-left: 0;
  }
}

@media (max-width: 575px) {
  .dg-clinet-brand-item .shape-4 {
    margin-top: 35px;
    margin-left: 15px;
  }
}

.dg-clinet-brand-item .shape-4:hover {
  -webkit-transform: translate(-20px, -30px);
  -ms-transform: translate(-20px, -30px);
  transform: translate(-20px, -30px);
  transition-delay: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
}

.dg-clinet-brand-item .shape-5 {
  margin-left: 125px;
  margin-top: 85px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .dg-clinet-brand-item .shape-5 {
    margin-left: 245px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dg-clinet-brand-item .shape-5 {
    margin-left: 200px;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dg-clinet-brand-item .shape-5 {
    margin-left: 80px;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dg-clinet-brand-item .shape-5 {
    margin-left: 100px;
    margin-top: 45px;
  }
}

@media (max-width: 575px) {
  .dg-clinet-brand-item .shape-5 {
    margin-left: 40px;
    margin-top: 40px;
  }
}

.dg-clinet-brand-item .shape-5:hover {
  -webkit-transform: translate(-20px, -30px);
  -ms-transform: translate(-20px, -30px);
  transform: translate(-20px, -30px);
  transition-delay: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
}

.dg-clinet-brand-trend {
  margin-top: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(74, 127, 228, 0.02);
  border-radius: 18px;
}

.dg-clinet-brand-trend p {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 0;
}

.dg-clinet-brand-trend p a {
  color: #05DAC3;
}

.dg-clinet-brand-trend p i {
  margin-left: 40px;
  margin-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dg-clinet-brand-trend p i {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media (max-width: 575px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .dg-clinet-brand-trend p i {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.tp-brand-3-area.breadcrumb-brand {
  background: #F3FBFE;
}

.tp-brand-3-right-color {
  background: #F3FBFE;
  position: absolute;
  top: -20px;
  right: 0;
  content: "";
  height: 1100px;
  width: 50%;
  z-index: -1;
}

.tp-brand-3-thumb img {
  object-fit: cover;
}

.tp-brand-3-wrapper {
  border-bottom: 1px solid #E6EFF4;
  padding-bottom: 50px;
}

.tp-brand-active {
  margin-left: -10px;
  margin-right: -150px;
}












/*----------------------------------------*/
/*  8.1 offer css
/*----------------------------------------*/
.tp-offer-area {
  margin-top: 100px;
  background-image: url('../../assets/images/offering/bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

/* .tp-offer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(243, 251, 254, 0.7) 55.73%, rgba(243, 251, 254, 0.7) 100%);
  z-index: -1;
} */

.tp-section-title {
  position: relative;
  margin-bottom: 30px;
  margin-top: 15px;
}

.tp-section-title-text-pera {
  line-height: 30px;
}

.web-development-text-point li {
  line-height: 24px;
}

.title-left-shape {
  position: absolute;
  bottom: -17px;
  left: 0;
}

.tp-section-title__pre .title-pre-color {
  color: var(--tp-theme-secondary);
}

.tp-section-title__pre img {
  display: block;
}

.tp-feature-wrapper {
  position: relative;
}

.tp-feature-wrapper p::after {
  position: absolute;
  content: "";
  background: #05DAC3;
  width: 2px;
  height: 41px;
  left: -10px;
  top: 5px;
}

.tp-offer-shape .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.5;
}

.tp-offer-shape .shape-2 {
  position: absolute;
  right: 124px;
  top: 210px;
  animation: tprotate 2s ease-in-out 0.1s forwards infinite alternate;
}

.tp-offer-wrapper {
  position: relative;
  background: var(--tp-common-white);
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.04);
  padding: 48px 0px 35px 0px;
}

.tp-offer-wrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  background: var(--tp-theme-secondary);
  height: 27px;
  width: 0px;
  transition: all 0.4s;
}

.tp-offer-wrapper:hover .tp-offer-wrapper-thumb {
  animation: icon-bounce 0.8s 1;
}

.tp-offer-wrapper:hover::after {
  width: 31px;
}

.tp-offer-wrapper:hover .tp-offer-wrapper-btn a i {
  transform: rotate(45deg);
}

.tp-offer-wrapper .offer-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  margin-bottom: 22px;
  color: var(--tp-theme-primary);
}

.tp-offer-wrapper-thumb img {
  margin-bottom: 30px;
  height: 66px;
}

.tp-offer-wrapper-btn a {
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: var(--tp-theme-secondary);
  transition: all 0.3s ease-in;
}

.tp-offer-wrapper-btn a:hover {
  color: var(--tp-theme-primary);
}

.tp-offer-wrapper-btn a i {
  transform: translateY(2px);
  margin-left: 10px;
  transition: all 0.3s ease-in;
}

.tp-offer-all-btn p {
  margin-top: 30px;
  position: relative;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--tp-heading-secondary);
  margin-bottom: 0;
}

.tp-offer-all-btn p a {
  color: var(--tp-theme-secondary);
  text-decoration: underline;
}

.tp-offer-all-btn p a span {
  margin-left: 10px;
  background: var(--tp-common-white);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: var(--tp-theme-secondary);
}

.tp-offer-all-btn p a span i {
  transform: translateX(2px);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-feature-wrapper.offer p::after {
    left: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-feature-wrapper.offer p::after {
    height: 45px;
  }
}

/*----------------------------------------*/
/*  8.2 offer css
/*----------------------------------------*/
.tp-portfolio-area {
  margin-top: 50px;
}

.tp-portfolio-item-wrapper:hover .tp-portfolio-item-thumb img {
  transform: scale(1.05);
}

.tp-portfolio-item-wrapper:hover .tp-portfolio-item-content {
  transform: translateY(-20px);
}

.tp-portfolio-item-thumb {
  overflow: hidden;
  /* height: 350px; */
  box-shadow: -1px 1px 16px 2px #01010121;
  border-radius: 10px;
  padding-bottom: 30px;
}

.tp-portfolio-cta-area {
  position: relative;
}

.tp-portfolio-item-content:hover .tp-portfolio-item-title a {
  color: var(--tp-common-white) !important;
  transition: all 0.5s ease-out 0s;
}

.tp-portfolio-item-thumb img {
  transition: all 0.3s ease-in-out;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-portfolio-item-thumb img {
    width: 100%;
  }
}

.tp-portfolio-item-content {
  background-color: var(--tp-common-white);
  padding: 35px 25px 32px;
  filter: drop-shadow(0px 3px 13px rgba(0, 0, 0, 0.078));
  margin-top: -85px;
  margin-bottom: 40px;
  margin-right: 20px;
  margin-left: 20px;
  transition: all 0.3s ease-in-out;
  box-shadow: -1px 1px 16px 2px #01010121;
  border-radius: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-portfolio-item-content {
    padding: 25px 15px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-portfolio-item-content {
    padding: 20px 20px 20px;
  }
}

.tp-portfolio-item-content:hover {
  background-color: var(--tp-theme-primary);
}

.tp-portfolio-item-content:hover .tp-portfolio-item-title {
  color: var(--tp-common-white);
}

.tp-portfolio-item-content-btn a {
  font-size: 15px;
  font-weight: 400;
  color: var(--tp-theme-secondary);
  text-decoration: none;
}

.tp-portfolio-item-content-btn a:hover {
  color: var(--tp-theme-secondary);
}

.tp-portfolio-item-content-btn a:hover i {
  text-decoration: none;
  animation: tfLeftToRight 0.5s forwards;
}

.tp-portfolio-item-content-btn a i {
  margin-left: 5px;
}

.tp-portfolio-item-subtitle {
  text-transform: uppercase;
  color: #969FA5;
  font-size: 14px;
  font-weight: 500;
}

.tp-portfolio-item-title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 15px;
  transition: none;
}

.tp-portfolio-item-title a {
  text-decoration: none;
  color: #000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-portfolio-item-title {
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  .tp-portfolio-item-title {
    font-size: 20px;
  }
}

.tp-portfolio-details-wrapper {
  margin-bottom: 60px;
}

.tp-portfolio-details-wrapper p {
  line-height: 1.95;
  color: #5C727D;
  margin-bottom: 40px;
}

.tp-portfolio-details-thumb {
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-portfolio-details-thumb img {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-portfolio-details-meta {
    margin-bottom: 15px;
  }
}

.tp-portfolio-details-meta-item {
  margin-right: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-portfolio-details-meta-item {
    margin-right: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-portfolio-details-meta-item {
    margin-right: 40px;
    margin-bottom: 30px;
  }
}

.tp-portfolio-details-meta-item p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #949392;
  margin-bottom: 0;
}

.tp-portfolio-details-meta-title {
  font-weight: 600;
  font-size: 16px;
  color: var(--tp-heading-secondary);
}

.tp-portfolio-details-meta-icon {
  margin-right: 10px;
}

.tp-portfolio-details-btn a {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  padding: 14px 55px;
  text-transform: capitalize;
  color: var(--tp-common-white);
  background: var(--tp-theme-secondary);
  transition: 0.3s;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-portfolio-details-btn a {
    margin-top: 20px;
  }
}

.tp-portfolio-details-btn a:hover {
  background: var(--tp-theme-primary);
}

.tp-portfolio-details-title {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 15px;
  color: var(--tp-theme-primary);
}

.tp-portfolio-details-list-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 18px;
  color: var(--tp-theme-primary);
}

.tp-portfolio-details-list ul li {
  list-style: none;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
}

.tp-portfolio-details-list ul li span {
  color: var(--tp-theme-secondary);
  margin-right: 8px;
}

.tp-portfolio-details-info-box {
  background: var(--tp-common-white);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 60px 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-portfolio-details-info-box {
    padding: 50px 30px;
  }
}

@media (max-width: 575px) {
  .tp-portfolio-details-info-box {
    padding: 40px 20px;
  }
}

.tp-portfolio-details-info-title {
  font-family: var(--tp-ff-body);
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: capitalize;
  color: var(--tp-heading-secondary);
}

.tp-portfolio-details-info-title span {
  margin-right: 12px;
}

.tp-portfolio-details-info-address {
  border-bottom: 1px solid rgba(21, 19, 19, 0.11);
  padding-bottom: 18px;
  margin-bottom: 30px;
}

.tp-portfolio-details-info-address p {
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #696969;
  line-height: 1.9;
}

.tp-portfolio-details-info-mail {
  border-bottom: 1px solid rgba(21, 19, 19, 0.11);
  padding-bottom: 18px;
  margin-bottom: 30px;
}

.tp-portfolio-details-info-mail-content span {
  margin-right: 10px;
}

.tp-portfolio-details-info-mail-text p {
  text-transform: capitalize;
  color: #696969;
  margin-bottom: 6px;
}

.tp-portfolio-details-info-mail-text-para a {
  text-decoration: none;
  color: #696969;
}

.tp-portfolio-details-info-mail-text-para a:hover {
  text-decoration: none;
  color: #696969;
}

.tp-portfolio-details-info-open {
  margin-bottom: 50px;
}

.tp-portfolio-details-info-open-icon {
  height: 78px;
  width: 78px;
  text-align: center;
  line-height: 78px;
  border-radius: 3px;
  background-color: var(--tp-theme-secondary);
  margin-right: 15px;
}

.tp-portfolio-details-info-open-content p {
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #696969;
  margin-bottom: 5px;
}

.tp-portfolio-details-info-social a {
  color: var(--tp-theme-primary);
  background: rgba(72, 164, 185, 0.06);
  border-radius: 73px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  margin-right: 20px;
  transition: 0.3s;
}

.tp-portfolio-details-info-social a img {
  width: 50%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-portfolio-details-info-social a {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-portfolio-details-info-social a {
    margin-right: 4px;
  }
}

@media (max-width: 575px) {
  .tp-portfolio-details-info-social a {
    margin-right: 10px;
  }
}

.tp-portfolio-details-info-social a:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-secondary);
}



.main-card {
  box-shadow: -1px 1px 16px 2px #01010121;
  border-radius: 15px;
  height: 100% !important;
}

.text-uppercase {
  margin-top: 7px;
}

.profile-image {
  height: 200px !important;
  width: 100% !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-select-none a {
  cursor: default;
}

.tp-cta-4-area {
  overflow: hidden;
  background: #F1FBFE;
}

.tp-cta-4-shape {
  position: absolute;
  top: 5px;
  left: 50%;
  z-index: 1;
}

.tp-cta-4-wrapper-left {
  position: relative;
  padding: 51px 15px 50px 55px;
  background: var(--tp-theme-secondary);
  height: 100%;
}

@media (max-width: 575px) {
  .tp-cta-4-wrapper-left {
    padding: 45px 15px 45px 25px;
  }
}

.tp-cta-4-wrapper-left .shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.tp-cta-4-wrapper-right {
  background: var(--tp-theme-primary);
  padding: 51px 15px 53px 55px;
  height: 100%;
}

@media (max-width: 575px) {
  .tp-cta-4-wrapper-right {
    padding: 45px 15px 45px 25px;
  }
}

.tp-cta-4-mail img {
  margin-right: 32px;
}

.tp-cta-4-mail a:hover {
  color: var(--tp-theme-primary);
  transition: all 0.5s ease-out 0s;
}

.tp-cta-4-content a:hover {
  color: var(--tp-common-white);
  transition: all 0.5s ease-out 0s;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-cta-4-mail img {
    margin-right: 15px;
  }
}

@media (max-width: 575px) {
  .tp-cta-4-mail img {
    margin-bottom: 15px;
  }
}

.tp-cta-4-mail a {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: var(--tp-common-white);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 66.5px;
  padding: 12px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-cta-4-mail a {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .tp-cta-4-mail a {
    font-size: 16px;
  }
}

.tp-cta-4-mail a span {
  text-decoration: underline;
}

.tp-cta-4-headphone-thumb {
  margin-right: 25px;
}

@media (max-width: 575px) {
  .tp-cta-4-headphone-thumb img {
    margin-bottom: 15px;
  }
}

.tp-cta-4-content p,
.tp-cta-4-content a {
  font-family: var(--tp-ff-heading);
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: var(--tp-common-white);
  margin-bottom: 6px;
}

@media (max-width: 575px) {

  .tp-cta-4-content p,
  .tp-cta-4-content a {
    font-size: 15px;
  }
}

.tp-cta-4-content p span,
.tp-cta-4-content a span {
  color: var(--tp-theme-secondary);
}

.tp-cta-4-content a {
  color: var(--tp-theme-secondary);
}




/*----------------------------------------*/
/*  2.11 Breadcrumb
/*----------------------------------------*/
.breadcrumb__area {
  background-image: url("../images/breadcrumb-bg-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 450px;
}

.breadcrumb__title {
  position: relative;
  font-weight: 700;
  font-size: 52px;
  line-height: 50px;
  color: #121D2C;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.breadcrumb-height {
  min-height: 450px;
  align-items: center;
}

.breadcrumb__title img {
  position: absolute;
  bottom: -5px;
  right: 35%;
  z-index: 1;
}

@media (max-width: 575px) {
  .breadcrumb__title img {
    bottom: -10px;
    right: 27%;
  }
}

.breadcrumb__title-pre {
  display: inline-block;
  height: 24px;
  line-height: 26px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  background: var(--tp-theme-2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 0 7px;
  margin-bottom: 12px;
}

.breadcrumb__list span {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3D3838;
  padding-right: 3px;
  margin-right: 3px;
  text-transform: capitalize;
}

.breadcrumb__list span a:hover {
  color: var(--tp-theme-primary);
}



/*----------------------------------------*/
/*  7.5 feature css
/*----------------------------------------*/
.tp-service-funfact-box {
  background: var(--tp-theme-primary);
  position: relative;
  z-index: 1;
}

.tp-service-title-wrapper .tp-section-title__pre {
  color: var(--tp-common-white);
}

.tp-service-title-wrapper .tp-section-title {
  color: var(--tp-common-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-service-breadcrumb-title-wrapper p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-service-breadcrumb-title-wrapper p {
    margin-bottom: 40px;
  }
}

.tp-service-wrapper {
  background: #0D1130;
  border-radius: 5px;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 65px 0 50px 0;
  transition: all 0.3s ease-in-out;
  border: 1px solid #0D1130;
}

.tp-service-wrapper:hover {
  border: 1px solid #06D9C3;
}

.tp-service-wrapper .service-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: var(--tp-common-white);
  margin-bottom: 35px;
  position: relative;
  z-index: 1;
}

.tp-service-wrapper .hide-text {
  position: absolute;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--tp-common-white);
  margin-bottom: 0;
  visibility: hidden;
  opacity: 0;
  top: 43%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 30px;
  transition: all 0.3s ease-in-out;
}

.tp-service-wrapper:hover .tp-service-icon {
  visibility: hidden;
  opacity: 0;
}

.tp-service-wrapper:hover .hide-text {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.tp-service-wrapper:hover .tp-service-btn a {
  color: var(--tp-common-white);
}

.tp-service-wrapper:hover .tp-service-btn a i {
  transform: rotate(45deg);
}

.tp-service-designation p {
  font-family: var(--tp-ff-poppins);
  font-weight: 500;
  font-size: 18px;
  color: var(--tp-common-white);
  border: 1px solid rgba(230, 237, 242, 0.15);
  border-radius: 50%;
  display: inline-block;
  line-height: 52px;
  width: 52px;
  height: 52px;
  position: absolute;
  right: 21px;
  top: 18px;
  z-index: 1;
}

.tp-service-icon {
  margin-bottom: 60px;
  transition: all 0.3s;
}

.tp-service-btn a {
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: var(--tp-theme-secondary);
  z-index: 1;
  position: relative;
  text-decoration: none;
}

.tp-service-btn a i {
  margin-left: 10px;
  transition: all 0.1s linear;
  transform: translateY(2px);
}

.tp-service-all-btn .tp-btn {
  padding: 21px 51px;
  border: 1px solid #252949;
}

.tp-service-3-area {
  background: var(--tp-theme-primary);
  overflow: hidden;
}

.tp-service-3-title-wrapper .tp-section-title {
  color: var(--tp-common-white);
}

.tp-service-3-title-wrapper .tp-section-title__pre {
  color: var(--tp-common-white);
}

.tp-service-3-title-wrapper p {
  font-weight: 400;
  font-size: 16px;
  color: #9C9C9C;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-service-3-title-wrapper p {
    margin-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .tp-service-3-title-wrapper p br {
    display: none;
  }
}

.tp-service-3-content {
  box-shadow: -1px 1px 16px 2px #01010121;
  border-radius: 15px;
  position: relative;
  background: #020626;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 58px 40px 36px 39px;
  overflow: hidden;
}

.tp-service-3-content.breadcrumb-item {
  background-color: var(--tp-common-white);
  border: 1px solid rgba(181, 182, 196, 0.28);
  transition: all 0.3s ease-in-out;
  border: 2px solid var(--tp-border-secondary);
}

.tp-service-3-content.breadcrumb-item:hover {
  background-color: var(--tp-theme-primary);
}

.tp-service-3-content.breadcrumb-item:hover .tp-service-breadcrumb-title {
  color: var(--tp-theme-secondary) !important;
}

.tp-service-3-content.breadcrumb-item .tp-service-3-content-thumb::before {
  background: rgba(6, 218, 195, 0.1);
}

.tp-service-3-content::after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  /* clip-path: polygon(29% 0, 100% 71%, 100% 0); */
  clip-path: circle(100.8% at 100% 0);
  background: var(--tp-theme-secondary);
  height: 42px;
  width: 0px;
  transition: all 0.4s;
}

.tp-service-3-content:hover::after {
  width: 42px;
}

.tp-service-3-content-thumb {
  position: relative;
}

.tp-service-3-content-thumb::before {
  position: absolute;
  content: "";
  top: -15px;
  left: 25px;
  height: 46px;
  width: 46px;
  background: rgba(255, 255, 255, 0.09);
  border-radius: 50%;
}

.tp-service-3-content-thumb img {
  margin-bottom: 30px;
  width: 44px;
  height: 44px;
}

.tp-service-3-content p {
  font-weight: 400;
  font-size: 16px;
  color: #9C9C9C;
  margin-bottom: 22px;
}

.tp-service-3-content .tp-service-btn a:hover i {
  transform: rotate(45deg);
}

.tp-service-3-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: var(--tp-common-white);
  margin-bottom: 20px;
}

.tp-service-3-title a:hover {
  color: var(--tp-theme-secondary);
}

.tp-about-call {
  border: 1px solid #E6EDF2;
  border-radius: 28px;
  padding: 12px 17px;
  width: 643px;
  margin-top: 12px;
}

.tp-about-call a {
  text-decoration: none !important;

}

.tp-about-call a p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--tp-heading-secondary);
  margin-bottom: 0;
}

.tp-about-call a p .myphone {
  height: 28px;
  width: 28px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-theme-secondary);
  transform: translateY(1px);
  font-size: 12px;
}

.tp-service-3-trend p {
  font-weight: 500;
  font-size: 15px;
  line-height: 36px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--tp-common-white);
  margin-bottom: 0;
}

.tp-service-3-trend p a {
  color: var(--tp-theme-secondary);
}

.tp-service-3-trend p i {
  margin-left: 20px;
  margin-right: 20px;
}

.tp-service-breadcrumb-area {
  margin-top: 35px;
  position: relative;
  overflow: hidden;
}

.tp-service-breadcrumb-title a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  transition: none;
}

.tp-service-breadcrumb-title a:hover {
  color: var(--tp-theme-secondary);
}

.tp-service-3-content.breadcrumb-item:hover .tp-service-breadcrumb-title a {
  color: var(--tp-theme-secondary);
}


.tp-service-details-wrapper p {
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  color: #5A627D;
  margin-bottom: 42px;
}

.tp-service-details-title {
  font-weight: 600;
  font-size: 28px;
  text-transform: capitalize;
  color: var(--tp-heading-secondary);
  margin-bottom: 15px;
}

.tp-service-details-thumb {
  margin-bottom: 40px;
}

.tp-service-details-thumb img {
  width: 100%;
}

.tp-video-play {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: auto;
}

.tp-about-3-img .shape-2 .tp-video-play a {
  height: 77px;
  width: 77px;
  line-height: 80px;
  font-size: 22px;
}

.tp-about-3-img .shape-2 .tp-video-play a::after {
  height: 94px;
  width: 94px;
  top: -7px;
  left: -7px;
  z-index: -1;
}

.fa-play:before {
  content: "\f04b";
}

.tp-video-play a {
  height: 119px;
  width: 119px;
  font-weight: 900;
  font-size: 24px;
  line-height: 122px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  transform: translateX(-50%);
  color: var(--tp-theme-secondary);
  background: var(--tp-common-white);
  box-shadow: 0px 0px 50px rgba(3, 4, 28, 0.1);
  z-index: 2;
}

.tp-video-play a::after {
  position: absolute;
  content: "";
  height: 146px;
  width: 146px;
  top: -13px;
  left: -13px;
  background: rgba(255, 255, 255, 0.28);
  border-radius: 50%;
  animation: pulse 2s infinite;
  z-index: -1;
}

.tp-service-details-thumb .tp-video-play a {
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 20px;
  top: 39%;
  left: 48%;
}

.tp-service-details-thumb .tp-video-play a::after {
  height: 94px;
  width: 94px;
  top: -7px;
  left: -7px;
  z-index: -1;
}

.tp-service-details-list p {
  margin-bottom: 15px;
}

.tp-service-details-list ul li {
  list-style: none;
  margin-bottom: 14px;
}

.tp-service-details-list ul li span {
  color: var(--tp-theme-secondary);
  margin-right: 10px;
}

.tp-service-widget {
  position: sticky;
  top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-service-widget {
    position: inherit;
  }
}

.tp-service-widget-tab ul li {
  list-style: none;
  margin-bottom: 13px;
}

.tp-service-widget-tab ul li .active {
  background-color: var(--tp-theme-primary);
  color: var(--tp-common-white);
}

.tp-service-widget-tab ul li .active i {
  background-color: var(--tp-theme-secondary);
}

.tp-service-widget-tab ul li a {
  position: relative;
  font-family: var(--tp-ff-heading);
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #101C2A;
  background: #F1FBFE;
  display: inline-block;
  width: 100%;
  padding: 24px 20px;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

.tp-service-widget-tab ul li a:hover {
  background-color: var(--tp-theme-primary);
  color: var(--tp-common-white);
}

.tp-service-widget-tab ul li a:hover i {
  background-color: var(--tp-theme-secondary);
}

.tp-service-widget-tab ul li a i {
  position: absolute;
  top: 30%;
  right: 7px;
  transform: translateY(-10px);
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 45px;
  transition: background-color 0.4s ease-in-out;
}

.tp-service-contact-form {
  background: #F8F8F8;
  padding: 80px 30px 65px;
}

@media (max-width: 575px) {
  .tp-service-contact-form {
    padding: 60px 30px 50px;
  }
}

.tp-service-contact-input {
  margin-bottom: 19px;
}

.tp-service-contact-input input {
  width: 100%;
  height: 60px;
  border: 1px solid #EFEFF2;
  background-color: var(--tp-common-white);
  outline: 0;
  color: var(--tp-common-black);
  padding: 0 25px;
  border-radius: 2px;
}

.tp-service-contact-input input::placeholder {
  font-family: var(--tp-ff-heading);
  font-weight: 500;
  font-size: 14px;
  line-height: 10px;
  color: #000D44;
  opacity: 0.5;
}

.tp-service-contact-input input:focus {
  border: 1px solid var(--tp-theme-secondary);
}

.tp-service-contact-input input:focus::placeholder {
  font-size: 0;
}

.tp-service-contact-input textarea {
  height: 167px;
  border: 1px solid #EFEFF2;
  background-color: var(--tp-common-white);
  resize: none;
  line-height: 3.5;
  border-radius: 2px;
}

.tp-service-contact-input textarea::placeholder {
  font-family: var(--tp-ff-heading);
  font-weight: 500;
  font-size: 14px;
  color: #000D44;
  opacity: 0.5;
}

.tp-service-contact-input textarea:focus {
  border: 1px solid var(--tp-theme-secondary);
}

.tp-service-contact-input textarea:focus::placeholder {
  font-size: 0;
}

/*----------------------------------------*/
/*  6.3 Footer Style 3
/*----------------------------------------*/
/* footer col design for home 3 */
.tp-footer-3-main-area {
  padding-top: 40px;
  padding-bottom: 52px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.08);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-footer-3-col-1 {
    margin-bottom: 40px;
  }
}

.tp-footer-3-col-2 {
  padding-left: 55px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-footer-3-col-2 {
    padding-left: 0;
    margin-bottom: 40px;
  }
}





/*----------------------------------------*/
/*  6.1 Footer Style 1
/*----------------------------------------*/
/* footer col design for home 1 */
/* .tp-footer-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
} */

/* .tp-footer-bg::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: #020C1B;
  opacity: 0.98;
} */
.tp-footer-bg {
  width: 100%;
  height: 100%;
  background: #020C1B;
}

.tp-footer-top-area {
  position: relative;
  padding-top: 48px;
  padding-bottom: 45px;
}

.tp-footer-top-shape {
  position: absolute;
  top: 0;
  left: 0;
  height: 160px;
  width: 100%;
  border-bottom: 1px solid rgba(217, 217, 217, 0.08);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-footer-top-shape {
    height: 200px;
  }
}

@media (max-width: 575px) {
  .tp-footer-top-shape {
    height: 250px;
  }
}

.tp-footer-top-contact a {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: var(--tp-common-white);
  background: #0E1825;
  border-radius: 66.5px;
  padding: 10px 24px;
}

@media (max-width: 575px) {
  .tp-footer-top-contact a {
    font-size: 17px;
  }
}

.tp-footer-top-contact a span {
  color: var(--tp-theme-secondary);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-footer-top-right {
    margin-top: 30px;
  }
}

.tp-footer-top-right-headphone img {
  margin-right: 20px;
  transform: translateY(10px);
}

.tp-footer-top-right-content p {
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  text-transform: uppercase;
  color: var(--tp-common-white);
  margin-bottom: -2px;
}

.tp-footer-top-right-content a {
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: var(--tp-theme-secondary);
}

.tp-footer-logo {
  margin-bottom: 30px;
  height: auto;
  width: 12%;
  max-width: 125px;
}

.tp-footer-logo img {
  height: 100%;
  width: 100%;
}

.tp-footer-info p {
  font-weight: 400;
  font-size: 16px;
  color: #B4B4B4;
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-footer-info p {
    margin-bottom: 20px;
  }
}

.tp-footer-main-area {
  position: relative;
  padding-top: 65px;
  padding-bottom: 52px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.08);
}

.tp-footer-main-location {
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-footer-main-location {
    padding-left: 25px;
  }
}

.tp-footer-main-location a {
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #B4B4B4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-footer-main-location a br {
    display: none;
  }
}

.tp-footer-main-location a i {
  color: var(--tp-theme-secondary);
  font-size: 20px;
  position: absolute;
  top: 10px;
  left: 0px;
}

.tp-footer-main-location a:hover {
  color: var(--tp-common-white);
}

.tp-footer-main-mail {
  position: relative;
  padding-left: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-footer-main-mail {
    padding-left: 30px;
  }
}

.tp-footer-main-mail a {
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #B4B4B4;
}

.tp-footer-main-mail a i {
  color: var(--tp-theme-secondary);
  font-size: 20px;
  position: absolute;
  top: 10px;
  left: 0px;
}

.tp-footer-main-mail a:hover {
  color: var(--tp-common-white);
}

.tp-footer-widget-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  color: var(--tp-common-white);
  display: inline-block;
  margin-bottom: 30px;
}

.tp-footer-widget ul li {
  list-style: none;
}

.tp-footer-widget ul li:not(:last-of-type) {
  margin-bottom: 22px;
}

.tp-footer-widget ul li a {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  color: #B4B4B4;
  transition: 0.3s ease;
}

.tp-footer-widget ul li a::before {
  position: absolute;
  content: "\e09f";
  font-family: "Font Awesome 6 Pro";
  top: 10px;
  left: -18px;
  font-size: 18px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--tp-theme-secondary);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.tp-footer-widget ul li a:hover {
  margin-left: 14px;
  color: var(--tp-common-white);
}

.tp-footer-widget ul li a:hover::before {
  opacity: 1;
  visibility: visible;
}

.tp-footer-widget-social a {
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-common-white);
  margin-right: 20px;
}

.tp-footer-widget-social a:hover {
  color: var(--tp-theme-secondary);
}

.tp-footer-author {
  margin-bottom: 32px;
}

.tp-footer-author-thumb {
  position: relative;
  z-index: 0;
}

.tp-footer-author-thumb::before {
  position: absolute;
  content: "";
  background-color: var(--tp-theme-secondary);
  height: 12px;
  width: 12px;
  border-radius: 50%;
  right: 24px;
  bottom: 4px;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-footer-author-thumb::before {
    right: 14px;
  }
}

.tp-footer-author-thumb img {
  filter: drop-shadow(0px 17px 28px rgba(72, 88, 174, 0.2));
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-footer-author-thumb img {
    margin-right: 10px;
  }
}

.tp-footer-author-content span {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--tp-common-white);
}

.tp-footer-from {
  position: relative;
  background: #081221;
  padding: 30px 20px 40px 20px;
}

.tp-footer-text-email {
  margin-bottom: 20px;
}

.tp-footer-text-email input {
  position: relative;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(237, 237, 237, 0.3);
  padding-left: 16px;
  padding-right: 35px;
  width: 100%;
  height: 41px;
  color: var(--tp-common-white);
}

.tp-footer-text-email input::placeholder {
  color: #B4B4B4;
}

.tp-footer-text-email input:focus {
  color: var(--tp-common-white);
  border-bottom: 1px solid #fff;
}

.tp-footer-text-email span {
  position: absolute;
  top: 36px;
  right: 38px;
}

.tp-footer-form-check {
  margin-bottom: 30px;
}

.tp-footer-form-check label {
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #B4B4B4;
}

.tp-footer-form-check input {
  border: 1px solid #4E545F;
  background: transparent;
  height: 23px;
  width: 23px;
  transform: translateY(-2px);
  margin-right: 8px;
  border-radius: 50% !important;
}

.tp-footer-form-check input:checked {
  background-color: transparent;
  box-shadow: none;
}

.tp-footer-copyright-area {
  padding-top: 22px;
  padding-bottom: 26px;
}

.tp-footer-3-area {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-footer-copyright-inner {
    margin-bottom: 15px;
  }
}



.tp-footer-copyright-inner:hover a {
  color: var(--tp-bg-primary)
}

.tp-footer-copyright-inner p,
.tp-footer-copyright-inner a {
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: var(--tp-common-white);
  margin-bottom: 0;
}

.tp-footer-copyright-inner p span,
.tp-footer-copyright-inner a span {
  color: var(--tp-theme-secondary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-footer-col-1 {
    margin-bottom: 40px;
  }
}

.tp-footer-col-2 {
  padding-left: 65px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-footer-col-2 {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-footer-col-2 {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-footer-col-2 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-footer-col-2 {
    margin-bottom: 40px;
  }
}

.tp-footer-col-3 {
  padding-left: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-footer-col-3 {
    padding-left: 0;
    margin-left: -30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-footer-col-3 {
    padding-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-footer-col-3 {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-footer-col-4 {
    margin-left: -72px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-footer-col-4 {
    margin-left: 0;
  }
}

/*----------------------------------------*/
/*  8.8 contact css
/*----------------------------------------*/
.tp-contact-area {
  margin-top: 50px;
}

.tp-contact-title {
  font-weight: 700;
  font-size: 45px;
  line-height: 65px;
  display: inline-block;
  text-transform: uppercase;
  color: var(--tp-heading-secondary);
  margin-bottom: 35px;
}

@media (max-width: 575px) {
  .tp-contact-title {
    font-size: 34px;
  }
}

.tp-contact-title span {
  color: var(--tp-theme-secondary);
}

.tp-contact-title-wrapper p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #7F7F8C;
  margin-bottom: 40px;
}

@media (max-width: 575px) {
  .tp-contact-title-wrapper p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-contact-content {
    margin-bottom: 40px;
  }
}

.tp-contact-content-mail {
  margin-bottom: 10px;
}

.tp-contact-content-mail-icon {
  margin-right: 55px;
}

@media (max-width: 575px) {
  .tp-contact-content-mail-icon {
    margin-right: 25px;
  }
}

.tp-contact-content-mail-icon span {
  color: var(--tp-theme-secondary);
}

.tp-contact-content-phone {
  margin-bottom: 50px;
}

.tp-contact-content-phone-icon {
  margin-right: 70px;
}

@media (max-width: 575px) {
  .tp-contact-content-phone-icon {
    margin-right: 35px;
    height: 30px;
    width: 30px;
  }

  .tp-contact-content-phone-icon img {
    height: 100%;
    width: 100%;
  }

  .tp-contact-content-mail-icon {
    height: 30px;
    width: 30px;
  }

  .tp-contact-content-mail-icon img {
    height: 100%;
    width: 100%;
  }
}

.tp-contact-content-phone-icon span {
  color: var(--tp-theme-secondary);
}

.tp-contact-content-phone-icon span svg {
  transform: translateX(12px);
}

@media (max-width: 575px) {
  .tp-contact-location-wrapper {
    flex-wrap: wrap;
  }
}

.tp-contact-location-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-transform: capitalize;
  color: var(--tp-heading-secondary);
  margin-bottom: 25px;
}

.tp-contact-location-title i {
  color: var(--tp-heading-secondary);
  font-size: 16px;
}

.tp-contact-location p {
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: #6F7183;
}

.tp-contact-item-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 2.8;
  color: var(--tp-heading-secondary);
  border-bottom: 1px solid #D9D9D9;
}

.tp-contact-item-title a {
  text-decoration: none;
  color: black;
}

@media (max-width: 575px) {
  .tp-contact-item-title {
    line-height: 1.8;
    font-size: 16px;
    width: 100%;
  }
}

.tp-contact-item-title a:hover {
  color: var(--tp-theme-secondary);
  transition: color 0.3s 0s ease-out;
}

.tp-contact-form {
  background: #F8F8F9;
  border-radius: 9px;
  padding: 60px 50px 40px 60px;
}


/* ----loader for Contact Page----- */

.loader-button {
  position: relative;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}


.loader {
  position: absolute;
  top: 18px;
  right: 6%;
  transform: translate(-50%, -50%);
  border: 6px solid #f3f3f3;
  border-top: 6px solid var(--tp-theme-secondary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.newsletter-svg {
  cursor: pointer;
}

.newsletter-loader {
  top: 0px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 767px) {
  .tp-contact-item-title-mobile-screen-block-view {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-contact-form {
    padding: 60px 30px 40px 30px;
  }
}

@media (max-width: 575px) {
  .tp-contact-form {
    padding: 60px 25px 40px 25px;
  }
}

.tp-contact-form-title {
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  text-transform: capitalize;
  color: #08151F;
}

@media (max-width: 575px) {
  .tp-contact-form-title {
    font-size: 30px;
  }
}

.tp-contact-form p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #555555;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-contact-form p br {
    display: none;
  }
}

.tp-contact-input {
  margin-bottom: 26px;
}

.tp-contact-input input {
  width: 100%;
  height: 60px;
  border: 1px solid #D4DCE0;
  background-color: #f8f8f9;
  outline: 0;
  color: var(--tp-common-black);
  padding: 0 25px;
  border-radius: 2px;
}

.tp-contact-input input::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: rgba(20, 33, 43, 0.8);
}

.tp-contact-input input:focus {
  border: 1px solid var(--tp-theme-secondary);
}

.tp-contact-input input:focus::placeholder {
  font-size: 0;
}

.tp-contact-input textarea {
  height: 271px;
  background-color: #f8f8f9;
  resize: none;
  line-height: 3.5;
  border-radius: 2px;
  border: 1px solid #D4DCE0;
  width: 100%;
  padding: 0 25px;
}

.tp-contact-input textarea::placeholder {
  font-weight: 400;
  font-size: 18px;
  color: rgba(20, 33, 43, 0.8);
}

.tp-contact-input textarea:focus {
  background: var(--tp-common-white);
  border: 1px solid var(--tp-theme-secondary) !important;
}

.tp-contact-input textarea:focus-visible {
  outline: none;
}

.tp-contact-input textarea:focus::placeholder {
  font-size: 0;
}

.tp-contact-cta-area {
  margin-top: -165px;
}

.tp-contact-map {
  height: 580px;
  width: 100%;
}

.tp-contact-map iframe {
  height: 100%;
  width: 100%;
  filter: saturate(0);
}

.home-page-google-map {
  height: 460px;
  width: 100%;
  /* margin-bottom: 20px; */
}

.google-map-main-container {
  position: relative;
}

.cat-area-card-google-map {
  position: absolute;
  height: 200px;
  width: 100%;
  bottom: 0;
  transform: translatey(20%);
}

@media only screen and (max-width: 1200px) {
  .cat-area-card-google-map {
    display: none;
  }
}

/*----------------------------------------*/
/*  2.3 Buttons
/*----------------------------------------*/

/* theme btn */
.tp-btn {
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-family: var(--tp-ff-heading);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 21px 48px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-theme-primary);
  border-radius: 10px;
}

.tp-contact-btn button {
  border: none;
  border-radius: 10px;
}

.tp-btn::before {
  content: "";
  position: absolute;
  height: 400px;
  width: 430px;
  top: 50%;
  left: 50%;
  background: var(--tp-theme-secondary);
  border-radius: 50%;
  transform: translateX(-22%) translateY(-42%) scale(0);
  -moz-transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  z-index: -1;
}

.tp-btn::after {
  position: absolute;
  content: "";
  background: var(--tp-theme-secondary);
  height: 34px;
  width: 36px;
  border-radius: 15px;
  right: -11px;
  bottom: -11px;
}

.tp-btn:hover {
  color: var(--tp-theme-primary);
}

.tp-btn:hover::before {
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.tp-btn i {
  margin-left: 16px;
  transform: translateY(2px);
}



/*----------------------------------------*/
/*  Home Page Testimonial
/*----------------------------------------*/

.testimonial-text-with-bg {
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  padding: 20px;
  background: linear-gradient(45deg, #00dac3, #008d82);
  /* border-radius: 10px; */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.testimonial-image-container {
  height: auto;
  width: 12%;
  max-width: 125px;
}

.testimonal-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: -1px 1px 16px 2px #01010121;
  overflow: hidden;
  max-width: 400px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.carousel .slide img {
  height: 250px;
}

.testimonal-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  width: 250px;
}

.testimonal-card-content {
  padding: 20px;
}

.testimonal-card-content h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.testimonal-card-content p {
  font-size: 16px;
}

@media (max-width: 768px) {
  .testimonal-card {
    max-width: 100%;
  }
}


.tp-support-breadcrumb {
  margin-top: 80px;
  margin-bottom: 14%;
}


.tp-about-breadcrumb {
  position: relative;
  margin-top: 50px;
}



.tp-counter-area {
  background: rgba(232, 247, 253, 0.5);
}

.tp-counter-area.counter-breadcrumb {
  background: none;
}

/* .tp-counter-area.counter-breadcrumb::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(232, 247, 253, 0.5);
  height: 68%;
  width: 100%;
  z-index: -1;
} */

.tp-counter-box {
  background: var(--tp-common-white);
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.06);
  padding: 38px 60px;
  border-radius: 15px;
}

.tp-counter-wrapper {
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-counter-wrapper {
    margin-bottom: 30px;
  }
}

.tp-counter-wrapper .counter-title {
  position: relative;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #090707;
  margin-bottom: 17px;
}

.tp-counter-wrapper .counter-title::after {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  border-radius: 50%;
  top: 0px;
  left: 135px;
  display: inline-block;
  opacity: 0;
  z-index: -1;
  background: var(--tp-theme-secondary);
  transition: all 0.3s ease-in;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-counter-wrapper .counter-title::after {
    left: 110px;
  }
}

.tp-counter-wrapper .counter-subtitle {
  font-family: var(--tp-ff-heading);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #090707;
}

.tp-counter-wrapper:hover .counter-title::after {
  opacity: 1;
}

.tp-counter-border::after {
  position: absolute;
  content: "";
  border-right: 1px solid #D4DBDE;
  height: 37px;
  right: 0px;
  top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-counter-border::after {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-counter-3-area {
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-counter-3-area {
    padding-top: 100px;
  }
}

.tp-counter-3-bg {
  width: 100%;
}

.tp-counter-3-bg .shape-1 {
  position: absolute;
  bottom: -65px;
  left: 0;
  width: 100%;
  z-index: 2;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-counter-3-bg .shape-1 {
    bottom: -45px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-counter-3-bg .shape-1 {
    bottom: -35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-counter-3-bg .shape-1 {
    bottom: 95px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-counter-3-bg .shape-1 {
    display: none;
  }
}

.tp-counter-3-wrapper {
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-counter-3-wrapper {
    margin-bottom: 30px;
  }
}

.tp-counter-3-wrapper .counter-title {
  position: relative;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: var(--tp-common-white);
  margin-bottom: 17px;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-counter-3-wrapper .counter-title {
    color: var(--tp-common-black);
  }
}

.tp-counter-3-wrapper .counter-title::after {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  border-radius: 50%;
  top: 0px;
  left: 150px;
  display: inline-block;
  opacity: 0;
  z-index: -1;
  background: var(--tp-theme-secondary);
  transition: all 0.3s ease-in;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-counter-3-wrapper .counter-title::after {
    left: 110px;
  }
}

.tp-counter-3-wrapper .counter-subtitle {
  position: relative;
  font-family: var(--tp-ff-heading);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  z-index: 1;
  color: var(--tp-common-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-counter-3-wrapper .counter-subtitle {
    color: var(--tp-common-black);
  }
}

.tp-counter-3-wrapper:hover .counter-title::after {
  opacity: 1;
}

.tp-counter-3-border::after {
  position: absolute;
  content: "";
  border-right: 1px solid rgba(217, 217, 217, 0.2);
  height: 37px;
  right: 0px;
  top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-counter-3-border::after {
    display: none;
  }
}




/*----------------------------------------*/
/*  7.2 About css
/*----------------------------------------*/
.box-plr {
  padding-left: 300px;
  padding-right: 99px;
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .box-plr {
    padding-left: 240px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .box-plr {
    padding-left: 200px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .box-plr {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .box-plr {
    padding-left: 0;
    padding-right: 0;
  }
}

.tp-about-area {
  overflow: hidden;
}

@media only screen and (min-width: 1701px) and (max-width: 1800px),
only screen and (min-width: 1600px) and (max-width: 1700px) {
  .tp-about-wrapper {
    padding-left: 80px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-about-wrapper {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-about-wrapper {
    padding-left: 102px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-wrapper {
    padding-left: 30px;
  }
}

@media (max-width: 575px) {
  .tp-about-wrapper {
    padding-left: 15px;
  }
}

.tp-about-wrapper-thumb {
  margin-left: -25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-about-wrapper-thumb {
    margin-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-about-wrapper-thumb img {
    max-width: 100%;
  }
}

.tp-about-shape .shape-1 {
  position: absolute;
  left: 0px;
  top: -115px;
  z-index: -1;
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .tp-about-shape .shape-1 {
    left: 0px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .tp-about-shape .shape-1 {
    left: -25px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-about-shape .shape-1 {
    left: -145px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-about-shape .shape-1 {
    left: 0px;
  }
}

.tp-about-item {
  position: relative;
}

.tp-about-item:hover .tp-about-item-thumb img {
  transform: scaleX(-1);
}

.tp-about-item::after {
  position: absolute;
  content: "";
  top: 0;
  right: -25px;
  width: 1px;
  height: 248px;
  border-right: 1px dashed #D3D6DA;
  z-index: 6;
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .tp-about-item::after {
    right: -15px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .tp-about-item::after {
    right: -2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-about-item::after {
    right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-item::after {
    right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-item::after {
    right: -12px;
  }
}

.tp-about-item-thumb {
  margin-bottom: 27px;
}

.tp-about-item-thumb img {
  height: 61px;
  width: 61px;
  transition: transform 0.5s ease;
}

.tp-about-item-content .about-title {
  font-family: var(--tp-ff-poppins);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: var(--tp-heading-secondary);
  margin-bottom: 15px;
}

.tp-about-item-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--tp-text-body);
}

@media only screen and (min-width: 1701px) and (max-width: 1800px),
only screen and (min-width: 1600px) and (max-width: 1700px),
only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-item-content p br {
    display: none;
  }
}

.tp-about-item-btn a {
  height: 50px;
  width: 50px;
  border: 1px solid #E8EBEE;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  border-radius: 50%;
  color: var(--tp-theme-primary);
}

.tp-about-item-btn a i {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.tp-about-item-btn a:hover i {
  animation: tfLeftToRight 0.5s forwards;
  color: var(--tp-theme-secondary);
}

.tp-about-title-wrapper {
  margin-bottom: 80px;
}

.tp-about-call {
  border: 1px solid #E6EDF2;
  border-radius: 28px;
  padding: 12px 17px;
  width: 643px;
  margin-top: 12px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-about-call {
    width: 100%;
  }
}

.tp-about-call a p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--tp-heading-secondary);
  margin-bottom: 0;
}

.tp-about-call a p span {
  margin-left: 18px;
  text-decoration: underline;
  transition: all 0.3s;
}

.tp-about-call a p span:hover {
  color: var(--tp-theme-secondary);
}

.tp-about-call a p i {
  height: 28px;
  width: 28px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-theme-secondary);
  transform: translateY(1px);
}

.tp-about-nav button {
  position: absolute;
  bottom: -100px;
  left: 10%;
  height: 51px;
  width: 55px;
  font-size: 16px;
  font-weight: 500;
  color: var(--tp-heading-secondary);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.01);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-about-nav button {
    display: none;
  }
}

.tp-about-nav button:hover {
  color: var(--tp-common-white);
  background: var(--tp-heading-secondary);
}

.tp-about-nav button.about-button-prev-1 {
  left: -25px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-about-breadcrumb {
    padding-top: 30px;
  }
}

@media (max-width: 575px) {
  .tp-about-breadcrumb-img img {
    width: 100%;
  }
}

.tp-about-3-area {
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-3-area {
    padding-top: 50px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-3-area {
    padding-top: 90px;
  }
}

@media (max-width: 575px) {
  .tp-about-3-area {
    padding-bottom: 100px;
    padding-top: 80px;
  }
}

.tp-about-3-area .shape-bg {
  position: absolute;
  left: -200px;
  top: 0;
  z-index: -1;
}

.tp-about-3-shape {
  position: absolute;
  top: 35%;
  left: 0;
  z-index: -1;
}


@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-about-3-shape {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-about-3-img {
    padding-bottom: 110px;
  }
}

.tp-about-3-img {
  position: relative;
}

.tp-about-3-img img {
  border-radius: 7px;
}

@media (max-width: 575px) {
  .tp-about-3-img img {
    max-width: 100%;
  }
}

.tp-about-3-img .shape-1 {
  position: absolute;
  top: 45px;
  right: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-3-img .shape-1 {
    right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-3-img .shape-1 {
    right: 30%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-3-img .shape-1 {
    top: 32px;
    right: 30px;
  }
}

@media (max-width: 575px) {
  .tp-about-3-img .shape-1 {
    display: none;
  }
}

.tp-about-3-img .shape-2 {
  position: absolute;
  bottom: -167px;
  right: 70px;
}

.tp-about-3-img .shape-2 .tp-video-play a:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-secondary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-3-img .shape-2 {
    right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-3-img .shape-2 {
    right: 5%;
    bottom: 9%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-3-img .shape-2 {
    bottom: 25px;
    right: 0;
  }
}

@media (max-width: 575px) {
  .tp-about-3-img .shape-2 {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-3-img .shape-3 {
    top: 28%;
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-3-img .shape-3 {
    top: 25%;
    right: 33%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-3-img .shape-3 {
    top: 26%;
    right: 14%;
  }
}

@media (max-width: 575px) {
  .tp-about-3-img .shape-3 {
    display: none;
  }
}

.tp-about-3-img .shape-4 {
  position: absolute;
  left: -7px;
  bottom: -116px;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-3-img .shape-4 {
    left: -15px;
    bottom: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-3-img .shape-4 {
    bottom: 50px;
  }
}

@media (max-width: 575px) {
  .tp-about-3-img .shape-4 {
    right: 8px;
    bottom: 25px;
    left: auto;
  }
}

/* .tp-about-3-img .shape-3 {
  position: absolute;
  bottom: -115px;
  left: -5px;
  z-index: 0;
} */

.tp-about-3-img .shape-3 {
  position: absolute;
  top: 40%;
  right: 21%;

}

@keyframes tprotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-3-img .shape-5 {
    left: -15px;
    bottom: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-3-img .shape-5 {
    bottom: 50px;
  }
}

@media (max-width: 575px) {
  .tp-about-3-img .shape-5 {
    right: 8px;
    bottom: 28px;
    left: auto;
  }
}

.tp-about-3-wrapper .tp-about-3-title-wrapper .tp-section-title {
  margin-bottom: 40px;
}

.tp-about-3-wrapper .text {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  color: #535353;
  margin-left: 13px;
  line-height: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-about-3-wrapper .text br {
    display: none;
  }
}

.tp-about-3-wrapper .text::after {
  position: absolute;
  top: 7px;
  left: -13px;
  content: "";
  width: 2px;
  height: 40px;
  background: var(--tp-theme-secondary);
}

@media (max-width: 575px) {
  .tp-about-3-wrapper .text::after {
    display: none;
  }
}

.tp-about-3-progressbar {
  margin-bottom: 55px;
}

.tp-about-3-progressbar:not(:last-of-type) {
  margin-right: 42px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-about-3-progressbar:not(:last-of-type) {
    margin-right: 20px;
  }
}

@media (max-width: 575px) {
  .tp-about-3-progressbar:not(:last-of-type) {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-about-3-progressbar {
    margin-bottom: 25px;
  }
}

.tp-about-3-progressbar .circular .knob {
  font-family: var(--tp-ff-p) !important;
  color: var(--tp-theme-secondary) !important;
  width: 96px;
  height: 56px;
  border: none;
  background: #ECEEF3;
  text-align: center;
  border-radius: 7px;
}

.tp-about-3-progressbar-title p {
  font-weight: 500;
  font-size: 18px;
  margin-left: 28px;
  color: var(--tp-heading-secondary);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-about-3-progressbar-title p {
    margin-left: 20px;
  }
}

.tp-about-3-btn-inner .tp-about-btn {
  margin-right: 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-3-btn-inner .tp-about-btn {
    margin-right: 20px;
  }
}

.tp-about-3-btn-inner .tp-about-btn .tp-btn {
  text-transform: uppercase;
  padding: 24px 32px;
  border-radius: 10px;
}

.tp-about-3-year p {
  font-weight: 500;
  font-size: 16px;
  color: var(--tp-heading-secondary);
}

@media (max-width: 575px) {
  .tp-about-3-year p {
    margin-top: 15px;
  }
}

.tp-about-3-year p span {
  font-weight: 400;
  font-size: 16px;
  color: #535353;
}







/*----------------------------------------*/

/*  7.4 feature css
/*----------------------------------------*/
.tp-feature-area {
  position: relative;
  padding-top: 60px;
  background: rgba(232, 247, 253, 0.5);
  overflow: hidden;
}

.tp-feature-area::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: var(--tp-theme-primary);
  height: 166px;
  width: 100%;
  left: 0;
}

.tp-feature-area.feature-breadcrumb {
  padding-top: 0;
}

.tp-feature-area.feature-breadcrumb::before {
  display: none;
}

.tp-feature-area.feature-breadcrumb .tp-feature-shape .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
}

.tp-feature-shape img {
  position: absolute;
  left: -212px;
  z-index: 0;
  bottom: -36px;
  opacity: 0.5;
}

.tp-feature-wrapper p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  justify-content: end;
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-feature-wrapper p {
    justify-content: start;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
  .tp-feature-wrapper p br {
    display: none;
  }
}

.tp-feature-wrapper p::after {
  position: absolute;
  content: "";
  background: #05DAC3;
  width: 2px;
  height: 41px;
  left: 128px;
  top: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-feature-wrapper p::after {
    left: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-feature-wrapper p::after {
    left: -15px;
    height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-feature-wrapper p::after {
    left: -10px;
  }
}

.tp-feature-item {
  text-align: center;
  background: var(--tp-common-white);
  box-shadow: 0px -4px 31px rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-feature-item {
    margin-bottom: 50px;
  }
}



.tp-feature-item-box .tp-feature-item-btn a {
  height: 50px;
  width: 50px;
  display: inline-block;
  line-height: 50px;
  border-radius: 50%;
  color: var(--tp-common-white);
  background: var(--tp-theme-secondary);
  position: absolute;
  bottom: -25px;
  left: 43%;
  text-align: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  scale: 0;
  z-index: 2;
}

.tp-feature-item-box .tp-feature-item-btn a:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.tp-feature-item-box:hover .tp-feature-item-btn a {
  opacity: 1;
  visibility: visible;
  scale: 1;
}

.tp-feature-item:hover .tp-feature-item-thumb .shape {
  opacity: 1;
  visibility: visible;
  top: 80px;
}

.tp-feature-item:hover .tp-feature-item-thumb .thumb {
  transform: scale(1.05);
}

.tp-feature-item:hover .tp-feature-item-wrapper::after {
  opacity: 1;
  visibility: visible;
  left: 0;
}

.tp-feature-item:hover .tp-feature-item-wrapper::before {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.tp-feature-item-wrapper {
  padding: 56px 12px 25px 0;
}

.tp-feature-item-wrapper::after {
  position: absolute;
  top: 0;
  content: "";
  background: var(--tp-theme-secondary);
  clip-path: polygon(0 0, 0% 100%, 80% 50%);
  width: 42px;
  height: 57px;
  opacity: 0;
  visibility: visible;
  transform: scaleY(1);
  left: -10px;
  transition: all 0.3s ease-in-out;
}

.tp-feature-item-wrapper::before {
  position: absolute;
  right: 0;
  content: "";
  background: #FFB302;
  clip-path: polygon(50% 15%, 0% 100%, 100% 100%);
  width: 52px;
  height: 37px;
  opacity: 0;
  visibility: visible;
  transform: scaleY(1);
  bottom: -10px;
  transition: all 0.3s ease-in-out;
}

.tp-feature-item-shape {
  position: absolute;
  top: 0;
  left: 0;
}

.tp-feature-item-thumb .shape {
  position: absolute;
  right: 120px;
  top: 100px;
  background: var(--tp-common-white);
  box-shadow: 6px -6px 4px rgba(21, 49, 74, 0.07);
  border-radius: 24.945px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  margin-bottom: 0;
  opacity: 0;
  visibility: visible;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-feature-item-thumb .shape {
    right: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-feature-item-thumb .shape {
    right: 150px;
  }
}

@media (max-width: 575px) {
  .tp-feature-item-thumb .shape {
    right: 60px;
  }
}

.tp-feature-item-thumb .thumb {
  border-radius: 88.5px;
  margin-bottom: 62px;
  overflow: hidden;
  transition: 0.2s 0s ease-in;
}

.tp-feature-item-content .feature-title {
  position: relative;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 33px;
  color: var(--tp-heading-secondary);
}

.tp-feature-item-content .feature-title span {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--tp-theme-secondary);
}

.tp-feature-item-content .feature-title:hover {
  color: var(--tp-theme-secondary);
}

.tp-feature-item-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 28px;
}

.tp-feature-area-2 {
  background: rgba(232, 247, 253, 0.5);
}

@media (max-width: 575px) {
  .tp-feature-thumb-2 img {
    width: inherit;
  }
}

.tp-feature-2-info {
  background: var(--tp-common-white);
  padding: 22px 40px 21px 47px;
  justify-content: space-between;
  width: 500px;
  position: absolute;
  bottom: 0;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
  transition: all 0.5s ease-in-out;
  z-index: 2;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-feature-2-info {
    padding: 26px 40px 20px 30px;
    width: 430px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-feature-2-info {
    padding: 26px 40px 20px 30px;
    width: 400px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-feature-2-info {
    padding: 20px;
    width: 300px;
  }
}

@media (max-width: 575px) {
  .tp-feature-2-info {
    padding: 20px;
    width: 275px;
  }
}

.tp-feature-2-info:hover .tp-feature-2-btn a i {
  transform: rotate(45deg);
}

.tp-feature-2-title {
  font-weight: 600;
  font-size: 25px;
  color: var(--tp-heading-secondary);
  background-image: linear-gradient(#03041C, #03041C), linear-gradient(#03041C, #03041C);
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
  transition: 0.3s ease;
}

.tp-feature-2-title:hover {
  background-size: 0 2px, 100% 1px;
}

.tp-feature-2-title-sub {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 18px;
  color: var(--tp-theme-secondary);
  margin-bottom: 10px;
  display: block;
}

.tp-feature-2-btn a {
  font-size: 20px;
  color: var(--tp-theme-secondary);
}

.tp-feature-2-btn a i {
  transition: all 0.3s ease-in-out;
}

.tp-feature-3-area {
  position: relative;
  background: var(--tp-bg-primary);
}

@media (max-width: 575px) {
  .tp-feature-3-area {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.tp-feature-3-content {
  position: relative;
}

.tp-feature-3-content-inner {
  position: relative;
  background: var(--tp-common-white);
  box-shadow: 0px 18px 83px rgba(63, 91, 143, 0.02);
  padding: 35px 30px 20px 38px;
}

@media (max-width: 575px) {
  .tp-feature-3-content-inner {
    padding: 15px;
  }
}

.tp-feature-3-content-inner::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  clip-path: polygon(29% 0, 100% 71%, 100% 0);
  background: var(--tp-theme-secondary);
  height: 42px;
  width: 42px;
  transition: all 0.4s;
}

.tp-feature-3-content-inner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  clip-path: polygon(29% 0, 100% 71%, 100% 0);
  background: var(--tp-theme-primary);
  height: 42px;
  width: 0px;
  z-index: 1;
  transition: all 0.3s;
}

.tp-feature-3-content-inner:hover::before {
  width: 42px;
}

.tp-feature-3-content::after {
  position: absolute;
  content: "";
  top: 25px;
  left: -38px;
  height: 79px;
  width: 1px;
  background: #D9D9D9;
}

@media only screen and (min-width: 1701px) and (max-width: 1800px),
only screen and (min-width: 1600px) and (max-width: 1700px) {
  .tp-feature-3-content::after {
    top: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 575px) {
  .tp-feature-3-content::after {
    top: 50px;
    left: -15px;
  }
}

.tp-feature-3-content-thumb {
  margin-right: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 575px) {
  .tp-feature-3-content-thumb {
    margin-right: 35px;
  }
}

.tp-feature-3-content .tp-feature-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-transform: capitalize;
  color: #121D2C;
  margin-bottom: 17px;
}

.tp-feature-3-content .tp-feature-title a:hover {
  color: var(--tp-theme-secondary);
}

.tp-feature-3-content p {
  font-weight: 400;
  font-size: 16px;
  color: #9C9C9C;
}

.tp-feature-3-text-style {
  padding-bottom: 190px;
}

@media (max-width: 575px) {
  .tp-feature-3-text-style {
    padding-bottom: 70px;
  }
}

.tp-feature-3-text-style .feature-title {
  position: relative;
  font-weight: 700;
  font-size: 150px;
  line-height: 1;
  text-align: center;
  color: transparent;
  text-transform: lowercase;
  /* background-image: url("../img/feature/home-3/text-img.jpg"); */
  -webkit-background-clip: text;
  background-size: auto;
  background-position: center;
  margin-bottom: 50px;
}

.tp-feature-3-text-style .feature-title::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 54px;
  background: var(--tp-theme-secondary);
  right: 23%;
  top: 55%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-feature-3-text-style .feature-title::after {
    right: 16%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-feature-3-text-style .feature-title::after {
    right: 7%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-feature-3-text-style .feature-title::after {
    display: none;
  }
}

.tp-feature-3-text-style .feature-title::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 54px;
  background: var(--tp-theme-secondary);
  left: 23%;
  top: 55%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-feature-3-text-style .feature-title::before {
    left: 16%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-feature-3-text-style .feature-title::before {
    left: 7%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-feature-3-text-style .feature-title::before {
    display: none;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .tp-feature-3-text-style .feature-title {
    font-size: 135px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-feature-3-text-style .feature-title {
    font-size: 115px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-feature-3-text-style .feature-title {
    font-size: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-feature-3-text-style .feature-title {
    font-size: 100px;
  }
}

@media (max-width: 575px) {
  .tp-feature-3-text-style .feature-title {
    font-size: 60px;
  }
}

.tp-feature-active-2 .splide__slide.is-visible.is-active .tp-feature-2-info {
  opacity: 1;
  visibility: visible;
  bottom: 34px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-feature-active-2 .splide__slide.is-visible.is-active .tp-feature-2-info {
    bottom: 20px;
  }
}

@media (max-width: 575px) {
  .tp-feature-active-2 .splide__slide.is-visible.is-active .tp-feature-2-info {
    bottom: 70px;
  }
}

/*----------------------------------------*/
/*  7.5 feature css
/*----------------------------------------*/
.tp-service-funfact-box {
  background: var(--tp-theme-primary);
  position: relative;
  z-index: 1;
}

.tp-service-title-wrapper .tp-section-title__pre {
  color: var(--tp-common-white);
}

.tp-service-title-wrapper .tp-section-title {
  color: var(--tp-common-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-service-breadcrumb-title-wrapper p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-service-breadcrumb-title-wrapper p {
    margin-bottom: 40px;
  }
}




.tp-about-3-shape {
  position: absolute;
  top: 35%;
  left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-about-3-shape {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .tp-about-3-img {
    padding-bottom: 110px;
  }
}

.tp-about-3-img img {
  border-radius: 7px;
}

@media (max-width: 575px) {
  .tp-about-3-img img {
    max-width: 100%;
  }
}

.tp-about-3-img .shape-1 {
  position: absolute;
  top: 45px;
  right: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-3-img .shape-1 {
    right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-3-img .shape-1 {
    right: 30%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-3-img .shape-1 {
    top: 32px;
    right: 30px;
  }
}

@media (max-width: 575px) {
  .tp-about-3-img .shape-1 {
    display: none;
  }
}

.tp-about-3-img .shape-2 {
  position: absolute;
  bottom: -167px;
  right: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-3-img .shape-2 {
    right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-3-img .shape-2 {
    right: 5%;
    bottom: 9%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-3-img .shape-2 {
    bottom: 25px;
    right: 0;
  }
}

@media (max-width: 575px) {
  .tp-about-3-img .shape-2 {
    display: none;
  }
}

.tp-about-3-img .shape-3 {
  position: absolute;
  top: 40%;
  right: 30%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-3-img .shape-3 {
    top: 28%;
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-3-img .shape-3 {
    top: 25%;
    right: 33%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-3-img .shape-3 {
    top: 26%;
    right: 14%;
  }
}

@media (max-width: 575px) {
  .tp-about-3-img .shape-3 {
    display: none;
  }
}

.tp-about-3-img .shape-4 {
  position: absolute;
  left: -7px;
  bottom: -116px;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-3-img .shape-4 {
    left: -15px;
    bottom: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-3-img .shape-4 {
    bottom: 50px;
  }
}

@media (max-width: 575px) {
  .tp-about-3-img .shape-4 {
    right: 8px;
    bottom: 25px;
    left: auto;
  }
}

.tp-about-3-img .shape-5 {
  position: absolute;
  bottom: -115px;
  left: -5px;
  z-index: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-3-img .shape-5 {
    left: -15px;
    bottom: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-3-img .shape-5 {
    bottom: 50px;
  }
}

@media (max-width: 575px) {
  .tp-about-3-img .shape-5 {
    right: 8px;
    bottom: 28px;
    left: auto;
  }
}

.About6Image {
  height: 436px;
  width: 350px;

}

.About7Image {
  width: 195px;
  height: 120px;

}

.About8Image {
  width: 360px;
  height: 239px;

}



/* --------------Lets Work together ---------------*/



.work-together-card {
  background-image: url('../images/brand-logo/shape-bg.png');
  background-size: cover;
  background-position: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}

.card-title {
  font-size: 30px;
  color: #333;
  margin-bottom: 10px;
}

.card-text {
  color: #777;
  margin-bottom: 20px;
}

.badge {
  display: inline-block;
  padding: 5px 10px;
  background-color: var(--tp-theme-secondary);
  color: #fff;
  border-radius: 20px;
}

.badge-font {
  font-size: 18px !important;
}

@media (max-width: 575px) {
  .badge-font {
    font-size: 14px !important;
  }

  .card-title {
    font-size: 20px;
  }

  .card-text {
    font-size: 12px;
  }

  .call-text {
    font-size: 15px;
  }

  .testimonial-quote {
    font-size: 12px;
  }
}

.call-text {
  font-weight: bold;
  margin-right: 10px;
  color: #333;
}








.services {
  margin: 20px 0;
}

.section-title {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
}

.service-cards {
  position: relative;
  display: flex;
  justify-content: center;
}

.service-card {
  position: relative;
  width: 300px;
  height: 200px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
}

.service-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.service-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.services {
  margin: 20px 0;
}

.section-title {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card {
  position: relative;
  width: 300px;
  height: 200px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s;
}

.service-card:hover {
  /* transform: rotate(5deg); */
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #00dac3;
  z-index: 1;
}

.service-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.service-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}



@media (max-width: 768px) {
  .service-card {
    width: 100%;
    margin: 10px 0;
  }

  .service-card:hover {
    transform: scaleX(1);
  }

}


/*----------------------------- Testimonial Section ----------------------*/
.testimonial-slider {
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  background-image: url('../images/brand-logo/shape-bg.png');
}

.testimonial {
  text-align: center;
  margin-bottom: 20px;
}

.testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.testimonial-name {
  margin-top: 10px;
  font-weight: bold;
}

.testimonial-quote {
  margin-top: 10px;
  font-weight: 500;
}

.slider-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.slider-control {
  margin: 0 10px;
  padding: 8px 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(to right, #ff9a9e, #fad0c4); */
  background-image: linear-gradient(to right, #00dac3, #00a7c9);
  opacity: 0.8;
  z-index: -1;
}

.testimonial-designation {
  font-weight: bold;
}

.slider-controls .tp-contact-btn button {
  width: 150px;
}


@media only screen and (max-width: 700px) {
  .breadcrumb__area {
    background-size: contain;
    padding-top: calc(65px + 36%);
    min-height: 0px;
    position: relative;
  }

  .breadcrumb-height {
    min-height: 0px;
  }

  .breadcrumb__title {
    font-size: 30px;
  }

  .breadcrumb__bg-overlay_second {
    position: absolute;
    /* z-index: 1000; */
    top: 40%;

  }

  .tp-about-breadcrumb {
    margin-top: 0px;
    padding-top: 0px;
  }

  .tp-contact-area {
    margin-top: 0px;
  }

  .tp-portfolio-area {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 500px) {

  .breadcrumb__bg-overlay_second {
    position: absolute;
    /* z-index: 1000; */
    top: 38%;
  }

  .breadcrumb__title img {
    bottom: 12px;
    right: 27%;
    width: 120px;
  }

  .breadcrumb__title {
    font-size: 20px;
  }

}


.validation-border {
  border: 1px solid red;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.color-red {
  font-size: 16px;
  color: red;
}

.validationText {
  font-size: 0.875em;
  color: #dc3545;

}